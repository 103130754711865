<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>商品分类</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box">
        <div class="input_box">
          <el-input v-model="searchForm.type" placeholder="类型名称"></el-input>
        </div>
        <div class="input_box"><el-button type="primary" class="el-icon-search" @click="searchTable"></el-button></div>
      </div>
      <div class="operate_btn">
<!--        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addGoods">添加</el-button>-->
        <el-button type="danger" :plain="true" icon="el-icon-delete" @click="deleteTest">批量删除</el-button>
      </div>
    </div>
    <div class="tbale_box">
      <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange" height="100%">
        <el-table-column type="selection" width="55" />
        <el-table-column fixed prop="id" label="ID" width="100" />
        <el-table-column prop="type" label="类型名称" />
        <el-table-column prop="createTime" label="创建时间" width="300" />
        <el-table-column prop="founder" label="创建人" width="300" />
        <el-table-column width="140" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button @click="editGoodsType(scope.row)" type="primary" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button type="danger" circle icon='el-icon-delete' @click="delGoodsType(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageBox.pageSize
      layout="total,sizes, prev, pager, next" :total=pageBox.total>
    </new-pagination>
    <!--新增编辑-->
    <el-dialog :visible.sync="editDialog" width="30%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
      <edit-from :operateBox="operateBox" :key="new Date().getTime()" @closed="closed"></edit-from>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/index'
import EditFrom from './components/edit-from'
export default {
  name: 'index',
  components: { EditFrom },
  data() {
    return {
      tableData: [],
      editDialog: false,
      operateBox: {
        title: '提示',
        operate: 'add',
        id: null
      },
      searchForm: {
        type: ''
      },
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      delGoodsIds: null
    }
  },
  created() {
    this.getGoodsType(this.searchForm, this.pageBox)
  },
  mounted() {

  },
  methods: {
    //获取商品分类列表
    async getGoodsType(data, page) {
      try {
        let res = await api.getGoodsTypeList(data, page)
        if (res.code == 200) {
          this.tableData = res.rows
          this.pageBox.total = res.total
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getGoodsType(this.searchForm, this.pageBox)
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getGoodsType(this.searchForm, this.pageBox)
    },
    //根据条件查询
    searchTable() {
      this.getGoodsType(this.searchForm, this.pageBox)
    },
    // 新增样例
    addGoods() {
      this.operateBox = {
        title: '新增商品类型',
        operate: 'add',
        row: null
      }
      this.editDialog = true
    },

    //编辑商品类型
    editGoodsType(row) {
      this.operateBox = {
        title: '修改商品类型',
        operate: 'edit',
        row: row
      }
      this.editDialog = true
    },

    //选中要删除的商品分类获取对应id
    handleSelectionChange(val) {
      let ids = []
      val.forEach(item => {
        ids.push(item.id)
      })
      this.delGoodsIds = ids
    },
    //单个删除商品类型
    delGoodsType(row) {
      console.log(row)
      this.$confirm('确定删除该数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delGoods([row.id])
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //批量删除按钮
    deleteTest() {
      if (this.delGoodsIds == null) {
        this.$message.info('请至少选中一件商品数据进行删除操作')
        return false
      }
      this.$confirm('确定删除该数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delGoods(this.delGoodsIds)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //批量删除
    async delGoods(ids) {
      try {
        let res = await api.goodsTypeDel(ids)
        console.log(ids)
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '删除成功'
          })
          this.getGoodsType(this.searchForm, this.pageBox)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    //关闭弹窗
    closed(val) {
      this.editDialog = false
      this.getGoodsType(this.searchForm, this.pageBox)
    }
  },
  watch: {}
}
</script>

<style scoped></style>
