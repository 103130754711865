<template>
  <div class="form_box">
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px" :label-position="labelPosition" class="demo-ruleForm">
      <el-form-item label="商品类型名称" prop="type">
        <el-input v-model="ruleForm.type"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
        <el-button @click="cancelFrom('ruleForm')">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import api from '@/api/index'
import debounce from 'lodash/debounce';
export default {
  name: 'edit-from',
  props: {
    operateBox: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      labelPosition: 'top',
      ruleForm: {
        type: '',
      }
    }
  },
  created() {
    if (this.operateBox.row != null) {
      this.ruleForm = this.operateBox.row
    }
  },
  methods: {
    /*新增商品分类*/
    async addGoods(data) {
      try {
        let res = await api.goodsTypeAdd(data)
        if (res.code == 200) {
          this.$message.success('添加成功')
          this.$emit('closed')
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    async updateGoods(data) {
      try {
        let res = await api.goodsTypeUpdate(data)
        if (res.code == 200) {
          this.$message.success('更新成功')
          this.$emit('closed')
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    submitForm: debounce(function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = this.ruleForm
          if (this.operateBox.operate == 'add') {
            data = {
              type: this.ruleForm.type
            }
            this.addGoods(data)
          } else {
            let data = {
              id: this.ruleForm.id,
              type: this.ruleForm.type
            }
            this.updateGoods(data)
          }
        } else {
          return false;
        }
      });
    }, 500),
      cancelFrom(formName) {
      //调用父组件的方法
      this.$emit('closed')
    }
  }
}
</script>

<style scoped></style>
